import 'antd/dist/reset.css';
import * as React from 'react';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { GlobalStyles } from './GlobalStyles';
import { AuthProvider } from '@frontend/auth/authContext';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@frontend/api/client';

export const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyles />
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  </QueryClientProvider>
);
