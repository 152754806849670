/* eslint-disable */
import type { ImagingStudy } from './imagingstudy';
import type { MedicationEntry } from './medications';
import type { Observation } from './observations';
import type { User } from './user';

export const protobufPackage = 'patient';

export interface Patient {
  name?: string;
  alternate_names?: string[];
  /** site specific patient MRN */
  mrn?: string;
  /** radiology patient id in case it is different from mrn */
  radiology_id?: string;
  /** analagous to prov_0001 et al */
  institution_id?: string;
  ordering_md?: string;
  date_of_birth?: string;
  indication?: PatientPrimaryIndication;
  histology?: PatientHistology;
  pd_l1?: PatientPD_L1;
  sex?: PatientSex;
  /** internal patient ID */
  id: string;
  observations?: Observation[];
  medication_entries?: MedicationEntry[];
  imaging_studies?: ImagingStudy[];
  notes?: PatientNote[];
  results?: PatientInferenceResult[];
  log?: PatientOperationsLog[];
  status?: PatientStatus;
  physician_name: string;
}

export enum PatientStatus {
  UNKNOWN = 'UNKNOWN',
  IMAGING_PENDING = 'IMAGING_PENDING',
  IMAGING_RECEIVED = 'IMAGING_RECEIVED',
  IMAGING_QC_FAIL = 'IMAGING_QC_FAIL',
  IMAGING_QC_PASS = 'IMAGING_QC_PASS',
  ANNOTATION_DONE = 'ANNOTATION_DONE',
  PREDICTION_DONE = 'PREDICTION_DONE',
}

export enum PatientPrimaryIndication {
  UNKNOWN_INDICATION = 'UNKNOWN_INDICATION',
  OTHER_INDICATION = 'OTHER_INDICATION',
  NSCLC_IV = 'NSCLC_IV',
  NSCLC_III = 'NSCLC_III',
}

export enum PatientSex {
  UNKNOWN = "UNKNOWN",
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum PatientHistology {
  UNKNOWN_HISTOLOGY = 'UNKNOWN_HISTOLOGY',
  MIXED_OR_OTHER = 'MIXED_OR_OTHER',
  ADENOCARCINOMA = 'ADENOCARCINOMA',
  SQUAMOUS = 'SQUAMOUS',
}

export interface PatientInferenceResult {
  /** Date the result was generated */
  result_generated_date?: string;
  /**
   * Date of latest patient info used to generate this result, eg. if we
   *  only want to generate "baseline" results despite a patient having
   *  followup data, this data will contain the "baseline" date
   */
  latest_data_date?: string;
  /** Version of the inference engine, eg. "0.1.3" etc */
  inference_engine_version?: string;
  /** Inference results */
  patient_level_io_response?: number;
}

export interface PatientPD_L1 {
  test_complete?: boolean;
  tps?: number;
}

/**
 * Freeform dated notes associated with a patient as entered
 * by a Physician, Nurse, or other end user.
 */
export interface PatientNote {
  date?: string;
  note?: string;
  user?: User;
}

/**
 * Operations log for a patient, programattic entries such as
 * "Patient Enrolled", "Imaging Received", "Value updated", ...
 */
export interface PatientOperationsLog {
  date?: string;
  note?: string;
}
