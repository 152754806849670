import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { Form, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { IPatientFormFields, PatientForm } from './PatientForm';
import { PatientConfirmationModal } from './PatientConfirmationModal';
import { PATIENTS_PATH } from '@frontend/app/paths';
import { useCreatePatient } from '@frontend/api/patients';

export const EnrollPatientPage: React.FC = () => {
  const [form] = Form.useForm<IPatientFormFields>();
  const navigate = useNavigate();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const handleFormSubmit = async (): Promise<void> => {
    try {
      await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const formData = Form.useWatch([], form);

  const { mutateAsync: createPatient } = useCreatePatient();
  const onConfirm = useCallback(async () => {
    try {
      const patient = await createPatient({
        radiology_id: formData.radiology_id,
        name: formData.name,
        sex: formData.sex,
        histology: formData.histology,
        mrn: formData.mrn,
        pd_l1: {
          test_complete: !!formData.pdl1_tps_pct,
          tps: formData.pdl1_tps_pct && formData.pdl1_tps_pct >= 1 ? formData.pdl1_tps_pct / 100.0 : undefined,
        },
        observations: formData.nlr
          ? [
            {
              test_name: 'NLR',
              test_value: formData.nlr.toString(),
              test_units: 'ratio',
              test_date: formData.nlr_test_date?.toISOString(),
            },
          ]
          : [],
        date_of_birth: formData.date_of_birth.toISOString(),
        physician_name: formData.physician_name,
      });

      if (patient) {
        await message.success('Success');
        navigate(generatePath(`${PATIENTS_PATH}`));
        form.resetFields();
      }
    } catch (error) {
      await message.error('Failed to enroll new patient');
    } finally {
      setIsConfirmModalOpen(false);
    }
  }, [navigate, formData, setIsConfirmModalOpen]);

  const onCancel = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  return (
    <Page title="Enroll patient" backTo={PATIENTS_PATH}>
      {
        isConfirmModalOpen && (
          <PatientConfirmationModal
            patient={formData}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )
      }
      <Pane>
        <PatientForm
          form={form}
          handleFormSubmit={handleFormSubmit}
          handleGoBack={() => navigate(PATIENTS_PATH)}
        />
      </Pane>
    </Page>
  );
};
