import { Spacer } from '@frontend/components/layout';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { PATIENTS_PATH, USERS_PATH } from './paths';
import { useAuthContext } from '@frontend/auth/authContext';
import { UserRole } from '@frontend/shared/models/user';

export const HeaderNav: React.FC = () => {
  const { user } = useAuthContext();

  return (
    <Container>
      <Link to={PATIENTS_PATH}>Patients</Link>
      {
        user?.role === UserRole.ADMIN && (
          <>
            <Spacer size={20} />
            <Link to={USERS_PATH}>Users</Link>
          </>
        )
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
`;

const Link = styled(NavLink)`
  white-space: nowrap;
  display: flex;
  justify-content: center;
  color: var(--color-text);
  padding: 5px;
`;
