import { Page } from '@frontend/app/Page';
import { Pane } from '@frontend/components/Pane';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';

import { AddUserButton } from './AddUserButton';
import { UsersTable } from './UsersTable';
import { useListUsers } from '@frontend/api/users';
import { useAuthContext } from '@frontend/auth/authContext';

const PAGE_SIZE = 10;

export const UsersPage: React.FC = () => {
  const { accessToken: isLoggedIn } = useAuthContext();
  const [page, setPage] = useState<number>(1);
  const { data: { results: users = [], total = 0 } = {}, error } = useListUsers({
    skip: (page - 1) * PAGE_SIZE,
    pageSize: PAGE_SIZE,
  }, {
    queryConfig: {
      enabled: !!isLoggedIn,
    },
  });

  useEffect(() => {
    if (error) {
      void message.error('Failed to load users');
    }
  }, [error]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <Page title="Users" extra={<AddUserButton />}>
      <Pane>
        <UsersTable
          users={users}
          total={total}
          page={page}
          pageSize={PAGE_SIZE}
          onPageChange={handlePageChange}
        />
      </Pane>
    </Page>
  );
};
