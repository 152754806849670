import { Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Page } from './Page';

export const NotFoundPage: React.FC = () => (
  <Page>
    <Helmet>
      <title>Not found</title>
    </Helmet>
    <Typography.Title level={2} style={{ margin: 0 }}>
      Page not found
    </Typography.Title>
  </Page>
);
