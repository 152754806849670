import { ConfigProvider } from 'antd';
import { createGlobalStyle } from 'styled-components';

enum Colors {
  white = '#FFFFFF',
  blue = '#6080E9',
  neutral = '#ABABAB',
  red = '#DC3312',
  yellow = '#FCE550',
  midnight = '#16385A',
  slate = '#3B5B75',
  grey = '#96B0D0',
  'light-grey' = '#EFF1F3',
  orange = '#CC5A22',
  green = '#50823C',
}

export const GlobalStyles = createGlobalStyle`
    // colors pulled from presentation template
    // https://oncai.sharepoint.com/:p:/r/_layouts/15/Doc.aspx?sourcedoc=%7B92A73939-A7C8-4B6E-99F7-D5F5D8C75AA7%7D&file=21OncAI_PresentationTemplate_Reference.pptx&action=edit&mobileredirect=true
    :root {
        --color-white: ${Colors.white};
        --color-blue: ${Colors.blue};
        --color-neutral: ${Colors.neutral};
        --color-red: ${Colors.red};
        --color-yellow: ${Colors.yellow};
        --color-midnight: ${Colors.midnight};
        --color-slate: ${Colors.slate};
        --color-grey: ${Colors.grey};
        --color-light-grey: ${Colors['light-grey']};
        --color-orange: ${Colors.orange};
        --color-green: ${Colors.green};

        --primary-color: var(--color-blue);
        --secondary-color: var(--color-yellow);
        --error-color: var(--color-red);
        --dark-color: var(--color-midnight);
        --text-color: var(--color-slate);
        --link-color: var(--color-blue);
        --header-background: var(--color-white);
        --header-border-color: var(--color-grey);
        --page-background: var(--color-light-grey);
        --body-background: var(--color-light-grey);
    }

    body {
        font-family: "Manrope Regular", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-variant-numeric: lining-nums;
        font-feature-settings: 'lnum' on;        
        background-color: var(--body-background);
    }

    body {
        color: var(--text-color);
    }

    h1 {
        font-size: 26px;
        font-weight: bold;        
    }

    a { 
        text-decoration: none;

        &:hover {
          color: var(--link-color);
          border-color: var(--link-color);
        }
    }

    .ant-typography,
    .ant-list-item,
    .ant-form-item,
    .ant-modal-title,
    .ant-input,
    .ant-select-item,
    .ant-select-selection-item,
    .ant-form-item-control .anticon,
    .ant-page-header-heading-title,
    th,
    td,
    label{
        color: var(--text-color)!important;
    }

    .ant-table-thead > tr > th {
      font-weight: bold;
      background-color: inherit;

      &::before {
        content: none!important;;
      }
    }

    .ant-form fieldset {
      legend {
        color: var(--text-color);
        margin-bottom: 30px; 
        border: 0;
        font-weight: 600;
      }
    }

    // remove spacing below tabs so it can rest on top of Pane
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }
`;

export function configureAntDTheme() {
  ConfigProvider.config({
    theme: {
      primaryColor: Colors.blue,
      errorColor: Colors.red,
      successColor: Colors.green,
      infoColor: Colors.neutral,
      warningColor: Colors.orange,
      processingColor: Colors.slate,
    },
  });
}
