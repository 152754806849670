import { Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_PATH } from './paths';

export const Login: React.FC = () => {
  return (
    <Link to={LOGIN_PATH}>
      <Button type="text">Sign In</Button>
    </Link>
  );
};
