import { User } from "@frontend/shared/models/user";
import { useCallback } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ServerSearchResponse, apiAxios } from "@frontend/api/axios";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "./client";
import { IMutationExtraProps, IQueryExtraProps } from "./shared";

const API_USERS_PATH = '/users';

interface IUseListUser {
  skip: number;
  pageSize: number;
}

export const useListUsers = (props: IUseListUser, extra?: IQueryExtraProps<ServerSearchResponse<User>, AxiosError>) =>  {
  const { skip, pageSize } = props;
  const { axiosConifg, queryConfig } = extra ?? {};

  const fetchUsers = useCallback(async () => {
    const { data } = await apiAxios.get<any, AxiosResponse<ServerSearchResponse<User>>>(`${API_USERS_PATH}`, {
      ...axiosConifg,
      params: {
        skip,
        limit: pageSize,
      },
    });

    return data;
  }, [skip, pageSize, axiosConifg]);

  return useQuery<ServerSearchResponse<User>, AxiosError>(['users', { skip, pageSize }], fetchUsers, queryConfig)
}

export const useCreateUser = (extra?: IMutationExtraProps<User, AxiosError, User>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};
  const createUser = useCallback(async (body) => {
    const { data } = await apiAxios.post<User, AxiosResponse<User>>(`${API_USERS_PATH}`, body, axiosConifg)
    return data;
  }, [axiosConifg]);

  return useMutation<User, AxiosError, User>(createUser, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('users'),
      ]);
      if (mutationConfig?.onSuccess) return await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}

interface IUseDeleteUser {
  id: string;
}

export const useDeleteUser = (extra?: IMutationExtraProps<unknown, AxiosError, IUseDeleteUser>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const deleteUser = useCallback(async (deleteParams: IUseDeleteUser) => {
    const { data } = await apiAxios.delete(`${API_USERS_PATH}/${encodeURIComponent(deleteParams.id)}`, axiosConifg);
    return data;
  }, [axiosConifg]);

  return useMutation<unknown, AxiosError, IUseDeleteUser>(deleteUser, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('users'),
      ])

      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}