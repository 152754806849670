import { PatientStatus } from '@frontend/shared/models/patient';
import { Tag } from 'antd';
import * as React from 'react';

export const PatientStatusTag: React.FC<{ status?: PatientStatus }> = ({
  status,
}) =>
  status ? (
    <Tag color={StatusColorMap[status]}>{status.toUpperCase()}</Tag>
  ) : null;

const StatusColorMap: Record<PatientStatus, string> = {
  [PatientStatus.UNKNOWN]: 'grey',
  [PatientStatus.IMAGING_PENDING]: 'purple',
  [PatientStatus.IMAGING_RECEIVED]: 'geekblue',
  [PatientStatus.IMAGING_QC_FAIL]: 'red',
  [PatientStatus.IMAGING_QC_PASS]: 'blue',
  [PatientStatus.ANNOTATION_DONE]: 'cyan',
  [PatientStatus.PREDICTION_DONE]: 'green',
};
