import { GrowingSpacer, Spacer } from '@frontend/components/layout';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Footer } from './Footer';
import { HeaderNav } from './HeaderNav';
import { Login } from './Login';
import { Logo } from './Logo/Logo';
import { Logout } from './Logout';
import { useAuthContext } from '@frontend/auth/authContext';

export interface IPageProps {
  title?: string;
  extra?: PageHeaderProps['extra'];
  onBack?: () => void;
  children: React.ReactNode;
  backTo?: string;
}

export const Page: React.FC<IPageProps> = ({
  children,
  title,
  onBack,
  extra,
  backTo,
}) => {
  const { accessToken: isLoggedIn } = useAuthContext();
  const navigate = useNavigate();

  const handleOnBack =
    onBack !== undefined
      ? onBack
      : backTo !== undefined
      ? () => {
          navigate(backTo);
        }
      : undefined;

  return (
    <Container>
      {title && (
        <Helmet>
          <title>Onc AI - {title}</title>
        </Helmet>
      )}
      <Header>
        <Logo />
        <Spacer size={40} />
        {isLoggedIn && <HeaderNav />}
        <GrowingSpacer />
        {isLoggedIn && <Logout />}
        {!isLoggedIn && <Login />}
      </Header>
      <Children>
        {title && (
          <>
            <PageHeader
              onBack={handleOnBack}
              title={title}
              style={{ padding: 0 }}
              extra={extra}
            />
            <Spacer size={20} />
          </>
        )}
        {children}
      </Children>
      <Footer />
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--page-background);
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px 10px;
  border-bottom: 1px solid var(--header-border-color);
  background-color: var(--header-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
`;

const Children = styled.section`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
