import { useCreateUser } from '@frontend/api/users';
import { User, UserRole } from '@frontend/shared/models/user';
import { Form, Input, Modal, Select, message } from 'antd';
import React from 'react';

interface Props {
  onAdd?: (user: User) => void;
  onCancel: () => void;
  open: boolean;
}

export const AddUserModal: React.FC<Props> = ({ onAdd, onCancel, open }) => {
  const [form] = Form.useForm<User>();

  const { mutateAsync: createUser, isLoading: isSaving } = useCreateUser();

  const handleOk = async () => {
    let user: User;
    try {
      user = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }

    try {
      await createUser(user);
      void message.success('Successfully added user');
      form.resetFields();
      if (onAdd) onAdd(user);
    } catch (error) {
      console.error(error);
      void message.error('Failed to add user');
    }
  };

  const handleClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title="Add User"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        layout="vertical"
        initialValues={{ role: UserRole.STAFF }}
      >
        <Form.Item
          label="Username"
          name="user_name"
          rules={[{ required: true, message: 'Username is required' }]}
        >
          <Input disabled={isSaving} autoFocus />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password disabled={isSaving} />
        </Form.Item>

        <Form.Item label="Role" name="role">
          <Select disabled={isSaving}>
            {Object.keys(UserRole).map((roleKey) => (
              <Select.Option key={roleKey} value={UserRole[roleKey]}>
                {roleKey}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
