import { UserRole } from '@frontend/shared/models/user';
import { LoginPage } from '@frontend/auth/LoginPage';
import { RolesRouteGuard } from '@frontend/auth/RolesRouteGuard';
import { EnrollPatientPage } from '@frontend/patients/EnrollPatientPage';
import { PatientEnrollmentQueuePage } from '@frontend/patients/PatientEnrollmentQueuePage';
import { PatientPage } from '@frontend/patients/PatientPage';
import { UsersPage } from '@frontend/users/UsersPage';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundPage } from './NotFoundPage';
import { ENROLL_PATH, LOGIN_PATH, PATIENTS_PATH, PATIENT_PATH, USERS_PATH } from './paths';
import { useAuthContext } from '@frontend/auth/authContext';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const AppRoutes: React.FC = () => {
  const { accessToken, loading } = useAuthContext();

  if (loading) {
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
  }

  return (
    <Routes>
      <Route
        path={PATIENTS_PATH}
        element={<RolesRouteGuard roles={[UserRole.ADMIN, UserRole.STAFF]} />}
      >
        <Route index element={<PatientEnrollmentQueuePage />} />
        <Route path={ENROLL_PATH} element={<EnrollPatientPage />} />
        <Route path={PATIENT_PATH} element={<PatientPage />} />
      </Route>
      <Route
        path={USERS_PATH}
        element={<RolesRouteGuard roles={[UserRole.ADMIN]} />}
      >
        <Route index element={<UsersPage />} />
      </Route>
      <Route
        index
        element={<Navigate to={accessToken ? PATIENTS_PATH : LOGIN_PATH} />}
      />
      <Route path={LOGIN_PATH} element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
