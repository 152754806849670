import { EditOutlined } from '@ant-design/icons';
import { Patient } from '@frontend/shared/models/patient';
import { Button } from 'antd';
import * as React from 'react';

import { EditPatientStatusModal } from './EditPatientStatusModal';

interface IProps {
  patient: Patient;
  onPatientUpdated?: () => void;
}

export const PatientStatusControl: React.FC<IProps> = ({
  patient,
}) => {
  const [isStatusModalOpen, setStatusModalOpen] = React.useState(false);

  const handleModalClosed = () => {
    setStatusModalOpen(false);
  };

  const isLoading = !patient;

  return (
    <>
      {
        <Button
          type="default"
          disabled={isLoading}
          onClick={() => setStatusModalOpen(true)}
          style={{ marginLeft: '10px' }}
        >
          <EditOutlined /> Update Status
        </Button>
      }
      {!isLoading &&
        patient.status !== undefined &&
        patient.id !== undefined && (
          <>
            <EditPatientStatusModal
              open={isStatusModalOpen}
              onClose={handleModalClosed}
              patientStatus={patient.status}
              patientId={patient.id}
            />
          </>
        )}
    </>
  );
};
