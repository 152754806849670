import React, { useCallback, useState } from "react";
import { List, Modal } from "antd";
import { IPatientFormFields } from "./PatientForm";
import { isMoment } from "moment";
import * as styles from './PatientConfirmationModal.module.scss';

const getData = (data: any) => {
  if (isMoment(data)) {
    return data.format('YYYY-DD-MM');
  } else {
    return data;
  }
}

interface IGenerateDescriptionProps {
  field: keyof IPatientFormFields;
  initialData?: Partial<IPatientFormFields>;
  patient: IPatientFormFields;
}

const generateDescription = (props: IGenerateDescriptionProps) => {
  const { field, initialData, patient } = props;

  return (
    <>
      {
        initialData != null && getData(initialData[field]) !== getData(patient[field]) && (
          <span className={styles.oldData}>{getData(initialData[field]) ?? '-'}</span>
        )
      }
      <span>{getData(patient[field]) ?? '-'}</span>
    </>
  )
}

export interface TPatientConfirmationModalProps {
  patient: IPatientFormFields;
  initialData?: Partial<IPatientFormFields>;
  onCancel?: () => void;
  onConfirm: (data: IPatientFormFields) => Promise<any>;
}

export const PatientConfirmationModal: React.FC<TPatientConfirmationModalProps> = (props) => {
  const { initialData, patient, onCancel: propsOnCancel, onConfirm: propsOnConfirm } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    if (propsOnCancel) propsOnCancel();
    setIsOpen(false);
  }, []);

  const onConfirm = useCallback(async () => {
    setLoading(true);
    if (propsOnConfirm) await propsOnConfirm(patient);
    setLoading(false);
    setIsOpen(false);
  }, [patient, propsOnConfirm]);

  return (
    <Modal
      title='Please confirm the patient data'
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <List>
        <List.Item>
          <List.Item.Meta
            title={'Ordering Physician'}
            description={generateDescription({ field: 'physician_name', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'Name'}
            description={generateDescription({ field: 'name', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'Date of Birth'}
            description={generateDescription({ field: 'date_of_birth', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'Sex'}
            description={generateDescription({ field: 'sex', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'MRN'}
            description={generateDescription({ field: 'mrn', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'Radiology ID'}
            description={patient.radiology_id ?? '-'}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'Histology'}
            description={generateDescription({ field: 'histology', initialData, patient })}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title={'NLR'}
            description={generateDescription({ field: 'nlr', initialData, patient })}
          />
        </List.Item>
        {
          patient.nlr != null && (
            <List.Item>
              <List.Item.Meta
                title={'NLR Test Date'}
                description={generateDescription({ field: 'nlr_test_date', initialData, patient })}
              />
            </List.Item>
          )
        }
        <List.Item>
          <List.Item.Meta
            title={'PD-L1 TPS'}
            description={generateDescription({ field: 'pdl1_tps_pct', initialData, patient })}
          />
        </List.Item>
      </List>
    </Modal>
  )
};