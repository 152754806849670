import { apiAxios } from "@frontend/api/axios";
import { User } from "@frontend/shared/models/user";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';

export const parseAccessToken = (token?: string | null) => {
  if (!token) return;

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

interface IToken {
  sub: string;
  iat: number;
  exp: number;
}

export const isAccessTokenValid = (token?: string | null) => {
  const parsedToken: IToken | undefined = parseAccessToken(token);
  if (parsedToken == null) return false;

  return new Date().getTime() < parsedToken.exp * 1000;
}

export const getAccessToken = () =>{
  const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  if (token != null && isAccessTokenValid(token)) return token;
};

interface ILoginResponse {
  access_token: string;
  token_type: string;
}

export const login = async (userName: string, password: string) => {
  const formData = new FormData();
  formData.append('grant_type', 'password');
  formData.append('username', userName);
  formData.append('password', password);
  const { data } = await apiAxios.post<ILoginResponse>('/auth/token', formData);

  if (data?.access_token != null) {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, data?.access_token);
    return data.access_token;
  } else {
    throw new Error('Access Token is undefined.');
  }
}

export const getCurrentUser = async () => {
  const { data } = await apiAxios.get<User>('/users/me');
  return data;
}

export const logout = async () => {
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
}