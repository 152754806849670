import { useAddNoteToPatient } from '@frontend/api/patients';
import { useAuthContext } from '@frontend/auth/authContext';
import { PatientNote } from '@frontend/shared/models/patient';
import { Form, Modal, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback } from 'react';

interface Props {
  onAdd?: () => void;
  onCancel: () => void;
  open: boolean;
  patientId: string;
}

export const AddNoteModal: React.FC<Props> = ({
  onAdd,
  onCancel,
  open,
  patientId,
}) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm<PatientNote>();
  const { mutateAsync: addNoteToPatient, isLoading: isSaving } = useAddNoteToPatient();

  const handleOk = useCallback(async () => {
    let note: PatientNote;
    try {
      note = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      return;
    }

    if (note.note && user) {
      try {
        await addNoteToPatient({
          id: patientId,
          data: {
            note: note.note,
            user,
            date: new Date().toISOString(),
          }
        });
        void message.success('Success');
        form.resetFields();
        if (onAdd) onAdd();
      } catch (error) {
        console.error(error);
        void message.error('Failed');
      }
    }
  }, [user]);

  const handleClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title="New note"
      open={open}
      onOk={handleOk}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
    >
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        initialValues={{ role: 'staff' }}
      >
        <Form.Item name="note" rules={[{ required: true }]}>
          <TextArea disabled={isSaving} autoFocus style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
