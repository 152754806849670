import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteUser } from '@frontend/api/users';
import { useAuthContext } from '@frontend/auth/authContext';
import { User, UserRole } from '@frontend/shared/models/user';
import { Popconfirm, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback } from 'react';

import styles from './UsersTable.module.scss';

interface Props {
  users?: User[];
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
}

interface IUserActions {
  user: User;
}

const UserActions: React.FC<IUserActions> = (props) => {
  const { user } = props;
  const { user: currentUser } = useAuthContext();

  const { mutateAsync: deleteUser } = useDeleteUser();
  
  const onDeleteUser = useCallback(async () => {
    await deleteUser({
      id: user.user_name,
    });
  }, [deleteUser])
  
  if (currentUser?.user_name === user.user_name) {
    return null;
  }

  return (
    <Space size="middle">
      <Popconfirm
        title="Delete user"
        description={(
          <>
            <span>Are you sure to delete this user?</span>
            <br />
            <span>He will lose access immediately.</span>
          </>
        )}
        onConfirm={onDeleteUser}
        okText="Yes"
        cancelText="No"
        okButtonProps={{
          danger: true,
        }}
      >
        <DeleteOutlined />
      </Popconfirm>
    </Space>
  )
}

export const UsersTable: React.FC<Props> = ({
  users,
}) => {
  const columns: ColumnsType<User> = [
    {
      title: 'User',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (role) => {
        return <Tag color={UserRoleColorMap[role]}>{role.toUpperCase()}</Tag>;
      },
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (_, user) => (
        <UserActions user={user} />
      ),
    },
  ];

  return (
    <Table
      rowKey="user_name"
      pagination={false}
      columns={columns}
      dataSource={users}
      rowClassName={styles.row}
    />
  );
};

const UserRoleColorMap: Record<UserRole, string> = {
  [UserRole.ADMIN]: 'purple',
  [UserRole.STAFF]: 'green',
};
