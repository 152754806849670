import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { getAccessToken, getCurrentUser, login as loginMethod, logout as logoutMethod } from "./utils";
import { User } from "@frontend/shared/models/user";

interface ILoginProps {
  userName: string;
  password: string;
  cb?: () => void;
}

const AuthContext = createContext<{
  accessToken?: string;
  loading?: boolean;
  login: (props: ILoginProps) => void;
  logout: (cb?: () => void) => void;
  user?: User;
  error?: any;
}>({
  login: () => {},
  logout: () => {},
});

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = (props: IAuthProviderProps) => {
  const { children } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string>();
  const [user, setUser] = useState<User>();
  const [error, setError] = useState();

  const logout = useCallback(async (cb?: () => void) => {
    setLoading(true);
    await logoutMethod()
    setAccessToken(undefined)
    if (cb) cb();
    setLoading(false);
  }, [setAccessToken]);

  const login = useCallback(async (loginProps: ILoginProps) => {
    setLoading(true);
    try {
      const t = await loginMethod(loginProps.userName, loginProps.password);
      setAccessToken(t);
      if (loginProps.cb) loginProps.cb();
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const t = getAccessToken();
    setAccessToken(t);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (accessToken != null) {
      getCurrentUser().then(setUser).catch((error) => { throw error })
    }
  }, [accessToken])
  

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        loading,
        login,
        logout,
        user,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context == null) throw new Error('useAuthContext must be used inside the AuthContext node.');

  return context;
};