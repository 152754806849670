import { getAccessToken } from '@frontend/auth/utils';
import baseAxios from 'axios';

export const axios = baseAxios.create({
  baseURL: process.env.ONC_API_URL,
})

axios.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
})

export const apiAxios = axios;

export interface ServerSearchResponse<T> {
  results: T[];
  total: number;
}
