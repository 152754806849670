export interface User {
  user_name: string;
  password: string;
  role: UserRole;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}
