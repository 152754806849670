import { Patient } from '@frontend/shared/models/patient';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import * as React from 'react';

import { PatientStatusTag } from '../PatientStatusTag';
import styles from './PatientEnrollmentTable.module.scss';

interface Props {
  patients: Patient[];
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onRowClick?: (patient: Patient) => void;
}

export const PatientEnrollmentTable: React.FC<Props> = ({
  page,
  patients,
  total,
  pageSize,
  onPageChange,
  onRowClick,
}) => {
  const columns: ColumnsType<Patient> = [
    {
      title: 'MRN',
      dataIndex: 'mrn',
      key: 'mrn',
    },
    {
      title: 'Radiology ID',
      dataIndex: 'radiology_id',
      key: 'radiology_id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      render: (rawDate) => {
        let d: Date | undefined;
        if (typeof rawDate === 'string') {
          d = new Date(rawDate);
        } else if (rawDate instanceof Date) {
          d = rawDate;
        }

        if (d) {
          return d.toISOString().split('T')[0];
        } else {
          return '-';
        }
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        return <PatientStatusTag status={status} />;
      },
    },
  ];

  const pagination: TablePaginationConfig = {
    total,
    onChange: onPageChange,
    current: page,
    pageSize,
    showSizeChanger: false,
  };

  return (
    <Table
      onRow={(patient) => {
        return {
          onClick: () => {
            if (onRowClick !== undefined) {
              onRowClick(patient);
            }
          },
        };
      }}
      rowKey="id"
      pagination={pagination}
      columns={columns}
      dataSource={patients}
      rowClassName={styles.row}
    />
  );
};
