import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const Footer: React.FC = () => {
  return (
    <Container>
      <Typography.Text>
        {new Date().getFullYear()} &copy; Onc.AI All Rights Reserved
      </Typography.Text>
    </Container>
  );
};

const Container = styled.section`
  background-color: var(--body-background);
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`;
