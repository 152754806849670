import { useAuthContext } from '@frontend/auth/authContext';
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from './paths';

export const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { logout: logoutMethod } = useAuthContext();

  const logout = useCallback(() => {
    logoutMethod(() => {
      navigate(LOGIN_PATH);
    });
  }, [logoutMethod]);

  return (
    <Button type="text" onClick={logout}>
      Sign out
    </Button>
  );
};
