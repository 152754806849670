import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App';
import { configureAntDTheme } from './app/GlobalStyles';

Sentry.init({
  dsn: 'https://58d7cd06861242ada44c311f15ef0cdd@o1432053.ingest.sentry.io/4503897495830528',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
configureAntDTheme();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw Error('#root element not found');
}
const root = createRoot(rootElement);
root.render(<App />);
