import { Patient, PatientHistology, PatientSex, PatientStatus } from "@frontend/shared/models/patient";
import { StatusCounts } from "@frontend/shared/types"
import { useCallback } from "react";
import { Observation } from "@frontend/shared/models/observations";
import { User } from "@frontend/shared/models/user";
import { useMutation, useQuery } from "react-query";
import { ServerSearchResponse, apiAxios } from "@frontend/api/axios";
import { AxiosError, AxiosResponse } from "axios";
import { queryClient } from "./client";
import { IMutationExtraProps, IQueryExtraProps } from "./shared";

const API_PATIENTS_PATH = '/patients';

export const useGetPatientsStatusCount = (extra?: IQueryExtraProps<StatusCounts, AxiosError>) => {
  const { axiosConifg, queryConfig } = extra ?? {};

  const fetchPatientsStatusCount = useCallback(async () => {
    const { data } = await apiAxios.get<StatusCounts>(`${API_PATIENTS_PATH}/status_counts`, axiosConifg);

    return data;
  }, [axiosConifg]);

  return useQuery<StatusCounts, AxiosError>('patientStatusesCount', fetchPatientsStatusCount, queryConfig);
}

interface IUseGetPatientsByStatus {
  skip: number;
  pageSize: number;
  statusFilters?: PatientStatus[];
}

export const useGetPatientsByStatus = (props: IUseGetPatientsByStatus, extra?: IQueryExtraProps<ServerSearchResponse<Patient>, AxiosError>) => {
  const { skip, pageSize, statusFilters } = props;

  const { axiosConifg, queryConfig } = extra ?? {};

  const fetchPatientsByStatus = useCallback(async () => {
    const { data } = await apiAxios.get<ServerSearchResponse<Patient>>(`${API_PATIENTS_PATH}/search_by_status`, {
      ...axiosConifg,
      params: {
        skip,
        limit: pageSize,
        statuses: Array.isArray(statusFilters) && statusFilters.length > 0 ? statusFilters.join(',') : undefined,
      },
    });

    return data;
  }, [skip, pageSize, statusFilters, axiosConifg]);

  return useQuery<ServerSearchResponse<Patient>, AxiosError>(['patientsByStatus', { skip, pageSize, statusFilters }], fetchPatientsByStatus, queryConfig);
}

interface IUseGetPatient {
  id: string;
};

export const useGetPatient = (props: IUseGetPatient, extra?: IQueryExtraProps<Patient, AxiosError>) => {
  const { id } = props;
  const { axiosConifg, queryConfig } = extra ?? {};

  const fetchPatient = useCallback(async () => {
    const { data } = await apiAxios.get<Patient>(`${API_PATIENTS_PATH}/${encodeURIComponent(id)}`, axiosConifg);

    return data;
  }, [id, axiosConifg]);

  return useQuery<Patient, AxiosError>(['patient', { id }], fetchPatient, queryConfig);
}

interface ICreatePatientInput {
  radiology_id?: string;
  name: string;
  sex: PatientSex;
  histology?: PatientHistology;
  mrn: string;
  date_of_birth: string;
  pd_l1?: {
    test_complete: boolean,
    tps?: number,
  };
  observations: Observation[];
  physician_name: string;
};

export const useCreatePatient = (extra?: IMutationExtraProps<Patient, AxiosError, ICreatePatientInput>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const createPatient = useCallback(async (body) => {
    const { data } = await apiAxios.post<Patient, AxiosResponse<Patient>, ICreatePatientInput>(`${API_PATIENTS_PATH}`, body, axiosConifg)
    return data;
  }, [axiosConifg]);

  return useMutation<Patient, AxiosError, ICreatePatientInput>(createPatient, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('patientStatusesCount'),
        queryClient.invalidateQueries('patientsByStatus'),
      ]);

      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}

interface IUpdatePatientInput {
  name?: string;
  sex?: PatientSex;
  histology?: PatientHistology;
  mrn?: string;
  radiology_id?: string;
  date_of_birth?: string;
  status?: PatientStatus;
  pd_l1?: {
    test_complete: boolean,
    tps?: number,
  };
  observations?: Observation[];
  physician_name?: string;
}

interface IUseUpdatePatient {
  id: string;
  data?: Partial<IUpdatePatientInput>;
}

export const useUpdatePatient = (extra?: IMutationExtraProps<Patient, AxiosError, IUseUpdatePatient>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const updatePatient = useCallback(async (updateParams: IUseUpdatePatient) => {
    const { data } = await apiAxios.patch<Patient, AxiosResponse<Patient>, IUpdatePatientInput>(`${API_PATIENTS_PATH}/${encodeURIComponent(updateParams.id)}`, updateParams.data, axiosConifg)
    return data;
  }, [axiosConifg]);

  return useMutation<Patient, AxiosError, IUseUpdatePatient>(updatePatient, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('patientStatusesCount'),
        queryClient.invalidateQueries('patientsByStatus'),
        queryClient.invalidateQueries(['patient', { id: args[0].id }]),
      ]);

      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}

interface IAddNoteToPatientInput {
  note: string;
  user: User;
  date: string;
}

interface IUserAddNoteToPatient {
  id: string;
  data?: IAddNoteToPatientInput;
}

export const useAddNoteToPatient = (extra?: IMutationExtraProps<Patient, AxiosError, IUserAddNoteToPatient>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const addNoteToPatient = useCallback(async (updateParams: IUserAddNoteToPatient) => {
    const { data } = await apiAxios.post<Patient, AxiosResponse<Patient>, IAddNoteToPatientInput>(`${API_PATIENTS_PATH}/${encodeURIComponent(updateParams.id)}/notes`, updateParams.data, axiosConifg)
    return data;
  }, [axiosConifg]);

  return useMutation<Patient, AxiosError, IUserAddNoteToPatient>(addNoteToPatient, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('patientStatusesCount'),
        queryClient.invalidateQueries('patientsByStatus'),
        queryClient.invalidateQueries(['patient', { id: args[0].id }]),
      ]);
      
      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}


interface IUseDeletPatient {
  id: string;
}

export const useDeletePatient = (extra?: IMutationExtraProps<unknown, AxiosError, IUseDeletPatient>) => {
  const { axiosConifg, mutationConfig } = extra ?? {};

  const deletPatient = useCallback(async (deleteParams: IUseDeletPatient) => {
    const { data } = await apiAxios.delete(`${API_PATIENTS_PATH}/${encodeURIComponent(deleteParams.id)}`, axiosConifg);
    return data;
  }, [axiosConifg]);

  return useMutation<unknown, AxiosError, IUseDeletPatient>(deletPatient, {
    ...mutationConfig,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries('patientStatusesCount'),
        queryClient.invalidateQueries('patientsByStatus'),
      ]);

      if (mutationConfig?.onSuccess) await mutationConfig.onSuccess(...args);

      return args[0];
    }
  });
}