import { Patient } from '@frontend/shared/models/patient';

export interface IPredictionReportInfo {
  ioResponsePrediction?: number;
  submitted?: string;
  patient?: {
    id?: string;
    name?: string;
    sex?: string;
    dateOfBirth?: string;
  };
  orderedBy?: {
    name?: string;
    institution?: string;
    email?: string;
    phone?: string;
  };
}

const ONC_REPORT_SERVICE_URL = process.env.ONC_REPORT_SERVICE_URL;
export function generatePatientReportUrl(patient: Patient) {
  if (!patient.results) {
    throw new Error('Cannot generate report url, not results exist');
  }

  const result = patient.results[patient.results.length - 1];
  const reportInfo: IPredictionReportInfo = {
    patient: {
      name: patient.name,
      dateOfBirth: patient.date_of_birth,
      sex: patient.sex,
      id: patient.id,
    },
    ioResponsePrediction: result.patient_level_io_response,
    submitted: result.result_generated_date,
  };
  const str = JSON.stringify(reportInfo);
  const base64Path = Buffer.from(str).toString('base64');
  return `${ONC_REPORT_SERVICE_URL}${base64Path}`;
}
