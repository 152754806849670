import { FileTextOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import { AddNoteModal } from './AddNoteModal';

interface Props {
  patientId: string;
}

export const AddNoteButton: React.FC<Props> = ({ patientId }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAdded = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <FileTextOutlined /> Add Note
      </Button>
      <AddNoteModal
        patientId={patientId}
        open={open}
        onAdd={handleAdded}
        onCancel={handleCancel}
      />
    </>
  );
};
