import { UserAddOutlined } from '@ant-design/icons';
import { User } from '@frontend/shared/models/user';
import { Button } from 'antd';
import React, { useState } from 'react';

import { AddUserModal } from './AddUserModal';

export const AddUserButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleUserAdded = (user: User) => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <UserAddOutlined /> Add User
      </Button>
      <AddUserModal
        open={open}
        onAdd={handleUserAdded}
        onCancel={handleCancel}
      />
    </>
  );
};
